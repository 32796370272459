<!-- Basic template with only header logo and footer -->
<template>
  <div>
    <Html class="h-full bg-gray-50">
      <Head>
        <Title>WeTix</Title>
      </Head>
    </Html>

    <Body class="h-full" />
    <header class="relative bg-white border-b border-gray-200 text-sm font-medium text-gray-700">
      <div class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div class="relative flex justify-end sm:justify-center">
          <a href="#" class="absolute left-0 top-1/2 -mt-4">
            <span class="sr-only">WeTix</span>
            <img src="/images/tunes-logo-small.png" alt class="h-10 w-auto">
          </a>
        </div>
      </div>
    </header>
    <main class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <slot />
    </main>
    <NotificationBasic />
    <footer aria-labelledby="footer-heading" class="bg-white">
      <h2 id="footer-heading" class="sr-only">
        Footer
      </h2>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="border-t border-gray-100 py-10 text-center">
          <p class="text-sm text-gray-500">
            &copy; 2022 WeTix Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
    <!-- <footer class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div class="border-t border-gray-200 py-12 text-center md:flex md:justify-between">
                <p class="text-base text-gray-400">&copy; WeTix Ltd. All rights reserved.</p>
                <div class="mt-6 flex justify-center space-x-8 md:mt-0">

                </div>
            </div>
        </footer>-->
  </div>
</template>

<script>

export default {
  components: {

  },
  setup () {
    return {

    }
  }
}
</script>
